<template>
  <div class="history" v-if="!$store.state.loading">
    <div class="cover" data-aos="fade-up" data-aos-duration="1000">
      <img :src="images[0]" alt="CarboBrake History"/>
    </div>
    <h1 data-aos="zoom-in" data-aos-duration="1000">
      {{ $t("history-title") }}
    </h1>

    <div class="section">
      <div
        class="section-title side"
        data-aos="fade-right"
        data-aos-duration="1000"
        v-html="$t('history-section1-title')"
      ></div>
      <div
        class="section-paragraph side"
        data-aos="fade-left"
        data-aos-duration="1000"
        v-html="$t('history-section1-paragraph')"
      ></div>
    </div>

    <div class="zoom-image-container" id="sede">
      <img :src="images[1]" alt="CarboBrake "/>
    </div>

    <div class="section timeline">
      <ul class="list-noAnim">
        <li data-aos="fade" data-aos-duration="1000"></li>
        <li data-aos="fade" data-aos-duration="1000">
          <div>
            <time datetime="2004">2004</time>
            <h3>{{ $t("history-2004") }}</h3>
            <img class="img-resp circle" :src="images[2]" alt="Suter Racing" />
          </div>
        </li>
        <li data-aos="fade" data-aos-duration="1000">
          <div>
            <time datetime="2006">2006</time>
            <h3>{{ $t("history-2006") }}</h3>
            <img class="img-resp circle" :src="images[3]" alt="Brembo" />
          </div>
        </li>
        <li data-aos="fade" data-aos-duration="1000">
          <div>
            <time datetime="2013">2013</time>
            <h3>{{ $t("history-2013") }}</h3>
            <img
              class="img-resp circle"
              :src="images[4]"
              alt="Akebono Brakes"
            />
          </div>
        </li>
        <li data-aos="fade" data-aos-duration="1000">
          <div>
            <time datetime="2016">2016</time>
            <h3>{{ $t("history-2016") }}</h3>
            <img class="img-resp circle" :src="images[5]" alt="SICOM Brakes" />
          </div>
        </li>
        <li data-aos="fade" data-aos-duration="1000">
          <div>
            <time datetime="2020">2020</time>
            <h3>{{ $t("history-2020") }}</h3>
            <img class="img-resp circle" :src="images[6]" alt="CARBO™" />
          </div>
        </li>
        <li data-aos="fade" data-aos-duration="1000">
          <div>
            <time datetime="2021">2021</time>
            <h3>{{ $t("history-2021") }}</h3>
            <img class="img-resp circle" :src="images[7]" alt="TÜV Austria" />
          </div>
        </li>
        <li data-aos="fade" data-aos-duration="1000"></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      images: [
        require("../assets/history.jpg"),
        require("../assets/Carbo-klassikstadt.jpg"),
        require("../assets/logo-suter.jpg"),
        require("../assets/logo-brembo.jpg"),
        require("../assets/logo-akebono.jpg"),
        require("../assets/logo-sicom.jpg"),
        require("../assets/logo-carbo-brake.jpg"),
        require("../assets/logo-tuv.jpg"),
      ],
    };
  },
  mounted() {
    ////LOAD ALL IMAGES IN PAGE
    this.$store.dispatch("LOAD_IMAGES", this.images).then(() => {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$store.commit("setFooterVisible");

          //simulate resize for scrolling transitions
          let event;
          if (typeof Event === "function") {
            event = new Event("resize");
          } else {
            //IE
            event = document.createEvent("Event");
            event.initEvent("resize", true, true);
          }
          window.dispatchEvent(event);
        }, 1100);

        //animazioni della pagina
        //SCROLLMAGIC
        // Declare Scene
        const sceneSede = this.$scrollmagic
          .scene({
            // ID of element where animation starts
            triggerElement: "#sede",

            // {0,0.5,1} - animations starts from {top,center,end} of window
            triggerHook: 0,

            offset: -60,
          })

          // Declaration of animation and attaching to element
          .setTween("#sede img", 0.8, {
            scale: 1,
          });
        // Helpful tags for orientation on the screen
        //.addIndicators({ name: 'cooling (duration: 300)' })

        // add scenes to window scrollmagic controller
        this.$scrollmagic.addScene(sceneSede);
      });
    });
  },
};
</script>
